/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export interface CVBatchTestRunRequest {
    run_uid?: string;
    test_submitter: string | null;
    test_description: string | null;
    test_start?: string | null;
    test_end?: string | null;
    provenance?: {
        api?: string,
        cv_service?: string,
        client_info?: any,
    } | null;
    args: {
        photo_source: string,
        output_path: string,
    };
    debug_log?: any;
    cv_interpretation_source: CVBatchTestRunRequest.cv_interpretation_source;
    cv_pipeline_info: {
        codename: string,
    };
    photo_result_store: CVBatchTestRunRequest.photo_result_store;
}

export namespace CVBatchTestRunRequest {

    export enum cv_interpretation_source {
        CVAAS = 'cvaas',
        DOCKER = 'docker',
        CVAAS_API = 'cvaas_api',
        LLM = 'llm',
    }

    export enum photo_result_store {
        DB = 'db',
        DYNAMODB = 'dynamodb',
    }


}
